import React from "react";
import Layout from "~components/layouts/index";

// markup
const Terms = () => {
  return (
    <Layout>
      <main>
        <title>Terms & Conditions</title>
        <p>Terms & LEGAL STUFF</p>
      </main>
    </Layout>
  );
};

export default Terms;
